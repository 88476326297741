import { ReactElement } from "react";
import { Summary, TextLink } from "@parselno/ui";
import Link from "next/link";
import { ErrorPageLayout } from "../components/ErrorPageLayout";

const Page = () => {
  return (
    <div>
      <h1>404</h1>
      <Summary>Beklager, vi kunne ikke finne siden du prøvde å finne.</Summary>

      <p>
        <Link passHref href="/">
          <TextLink>Gå til forsiden</TextLink>
        </Link>
      </p>
    </div>
  );
};

export default Page;

Page.getLayout = function getLayout(page: ReactElement) {
  return <ErrorPageLayout>{page}</ErrorPageLayout>;
};
