import React from "react";
import styled from "styled-components";
import { Container } from "@parselno/ui";

const Layout = styled.div`
  text-align: center;
  h1 {
    margin-top: 2rem;
    font-size: 4rem;
  }
`;
export const ErrorPageLayout: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Layout>
      <Container>{children}</Container>
    </Layout>
  );
};
